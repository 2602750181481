import { CONSTANTS } from "./constants";

export const computeNewCanvasWidth = (
  targetHeight: number
): number => targetHeight
  * CONSTANTS.CANVAS_INITIAL_WIDTH
  / CONSTANTS.CANVAS_INITIAL_HEIGHT;
export const computeNewCanvasHeight = (
  targetWidth: number
): number => targetWidth
  * CONSTANTS.CANVAS_INITIAL_HEIGHT
  / CONSTANTS.CANVAS_INITIAL_WIDTH;
export const resizeCanvasTo = (
  canvasElement: HTMLCanvasElement,
  newWidth: number,
  newHeight: number
): void => {
  if (!canvasElement) return;

  canvasElement.style.width = `${newWidth}px`;
  canvasElement.style.height = `${newHeight}px`;
};
export const resizeCanvas = (
  canvasElement: HTMLCanvasElement,
  targetWidth: number,
  targetHeight: number
): void => {
  const newWidth = computeNewCanvasWidth(targetHeight);
  const newHeight = computeNewCanvasHeight(targetWidth);
  const twoScenarios = [
    { width: targetWidth, height: newHeight },
    { width: newWidth, height: targetHeight }
  ].sort((
    a,
    b
  ) => a.width - b.width);
  const finalScenario = twoScenarios[0];
  resizeCanvasTo(canvasElement, finalScenario.width, finalScenario.height);
};