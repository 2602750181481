import React, { useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import WeeksGridTwo from "./WeeksGridTwo";
import { Life } from "../models";
import { Params } from "../Routes";
import { getLifeFromAPI } from "../helpers";
import { useParams } from "react-router-dom";
import { allAchievementsAtom } from "../atoms";
import { useRecoilState } from "recoil";

const useStyle = makeStyles({
  wrapper: {}
});

const WeeksGridWrapper = (): React.ReactElement => {
  const classes = useStyle();
  const [currentLife, setCurrentLife] = useState<Life>();
  const [allAchievements] = useRecoilState(allAchievementsAtom);
  const { lifeId } = useParams<Params>();

  if (!lifeId) return <></>;

  useEffect(() => {
    if (!lifeId) return;

    getLifeFromAPI(lifeId).then(value => {
      if (!value) return;

      const { title, year } = value;
      if (!title) return;
      if (!year) return;

      setCurrentLife({
        id: value.id,
        title,
        year
      });
    });
  }, [lifeId]);

  if (!currentLife) return <></>;
  if (!currentLife.year) return <></>;

  return <Box className={classes.wrapper}>
    <WeeksGridTwo startYear={currentLife.year} achievements={allAchievements} />
  </Box>;
};

export default WeeksGridWrapper;
