import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { useRecoilState } from "recoil";
import { allLifeAtom } from "../atoms";
import debounce from "lodash/debounce";
import { cloneLifeMap, getLifeFromAPI, updateTitleToAPI } from "../helpers";

const useStyle = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  }
});

type IProps = {
  id: string,
}

const EditableLifeTitle = (props: IProps): React.ReactElement => {
  const classes = useStyle();
  const [textValue, setTextFieldValue] = useState<string>();
  const [allLifes, setAllLifes] = useRecoilState(allLifeAtom);
  const currentLife = allLifes.get(props.id);

  useEffect(() => {
    if (!currentLife) return;

    setTextFieldValue(currentLife.title);
  }, []);

  const setTitle = (title: string) => setAllLifes(cloneLifeMap(allLifes).set(props.id, {
    ...(allLifes.get(props.id)),
    id: props.id,
    title
  }));

  const setTitleToApi = (title: string) => {
    getLifeFromAPI(props.id).then(value => {
      if (!value) return;

      updateTitleToAPI(props.id, title);
    });
  };

  const debouncedSaveToAtom = useCallback(
    debounce((nextValue: string) => setTitle(nextValue), 1000),
    []
  );

  const debouncedSaveToAPI = useCallback(
    debounce((nextValue: string) => setTitleToApi(nextValue), 1000),
    []
  );

  useEffect(() => {
    if (!textValue) return;
    if (!currentLife) return;
    if (textValue === currentLife.title) return;

    debouncedSaveToAtom(textValue);
    debouncedSaveToAPI(textValue);
  }, [textValue]);

  if (!textValue) return <></>;

  return <TextField
    className={classes.wrapper}
    value={textValue}
    onChange={event => setTextFieldValue(event.target.value)}
  />;
};

export default EditableLifeTitle;
