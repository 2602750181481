import React from "react";
import { Fab, makeStyles } from "@material-ui/core";
import { cloneLifeMap, createNewLife } from "../helpers";
import { allLifeAtom } from "../atoms";
import { useRecoilState } from "recoil";
import AddIcon from "@material-ui/icons/Add";

const useStyle = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "fixed",
    bottom: "25px",
    left: "25px"
  }
});

const NewLifeButton = (): React.ReactElement => {
  const classes = useStyle();
  const [allLifes, setAllLifes] = useRecoilState(allLifeAtom);

  const onButtonClick = () => {
    createNewLife().then(lifeFromAPI => {
      if (!lifeFromAPI) return;
      setAllLifes(cloneLifeMap(allLifes).set(lifeFromAPI.id, {
        id: lifeFromAPI.id,
        title: lifeFromAPI.title,
        year: lifeFromAPI.year
      }));
    });
  };

  return <Fab onClick={onButtonClick} className={classes.wrapper}>
    <AddIcon />
  </Fab>;
};

export default NewLifeButton;
