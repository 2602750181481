import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { useRecoilState } from "recoil";
import { allLifeAtom } from "../atoms";
import debounce from "lodash/debounce";
import { cloneLifeMap, getLifeFromAPI, updateYearToAPI } from "../helpers";

const useStyle = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  }
});

type IProps = {
  id: string,
}

const EditableLifeYear = (props: IProps): React.ReactElement => {
  const classes = useStyle();
  const [textValue, setTextFieldValue] = useState<number>();
  const [allLifes, setAllLifes] = useRecoilState(allLifeAtom);
  const currentLife = allLifes.get(props.id);

  useEffect(() => {
    if (!currentLife) return;

    setTextFieldValue(currentLife.year);
  }, []);

  const setYear = (year: number) => setAllLifes(cloneLifeMap(allLifes).set(props.id, {
    ...(allLifes.get(props.id)),
    id: props.id,
    year
  }));

  const setYearToApi = (year: number) => {
    getLifeFromAPI(props.id).then(value => {
      if (!value) return;

      updateYearToAPI(props.id, year);
    });
  };

  const debouncedSaveToAtom = useCallback(
    debounce((nextValue: number) => setYear(nextValue), 1000),
    []
  );

  const debouncedSaveToAPI = useCallback(
    debounce((nextValue: number) => setYearToApi(nextValue), 1000),
    []
  );

  useEffect(() => {
    if (!textValue) return;
    if (!currentLife) return;
    if (textValue === currentLife.year) return;

    debouncedSaveToAtom(textValue);
    debouncedSaveToAPI(textValue);
  }, [textValue]);

  if (textValue === null || textValue === undefined) return <></>;

  return <TextField
    className={classes.wrapper}
    value={textValue}
    type="number"
    onChange={event => setTextFieldValue(parseInt(event.target.value))}
  />;
};

export default EditableLifeYear;
