import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useRecoilState } from "recoil";
import { allLifeAtom } from "../atoms";
import EditableLifeLine from "./EditableLifeLine";
import { getAllLifes } from "../helpers";

const useStyle = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
});

const EditableLifeList = (): React.ReactElement => {
  const classes = useStyle();
  const [allLifes, setAllLifes] = useRecoilState(allLifeAtom);

  useEffect(() => {
    getAllLifes().then(value => {
      if (!value) return;

      setAllLifes(value);
    });
  }, []);

  return <Box className={classes.wrapper}>
    {Array.from(allLifes.values()).map(value => (
      <EditableLifeLine key={value.id} id={value.id} />
    ))}
  </Box>;
};

export default EditableLifeList;
