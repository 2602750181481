/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLife = /* GraphQL */ `
  query GetLife($id: ID!) {
    getLife(id: $id) {
      id
      title
      Achievements {
        nextToken
      }
      year
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLives = /* GraphQL */ `
  query ListLives(
    $filter: ModelLifeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        year
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAchievement = /* GraphQL */ `
  query GetAchievement($id: ID!) {
    getAchievement(id: $id) {
      id
      title
      color
      date
      lifeID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAchievements = /* GraphQL */ `
  query ListAchievements(
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAchievements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        color
        date
        lifeID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
