export const CONSTANTS = {
  CANVAS_INITIAL_WIDTH: 550,
  CANVAS_INITIAL_HEIGHT: 850,
  Y_FACTOR: 9,
  X_FACTOR: 9,
  YEAR_LABEL_X_OFFSET: 8,
  GRID_X_OFFSET: 55,
  Y_OFFSET: 20,
  HEIGHT_OF_YEAR: 10,
  CIRCLE_RADIUS: 4,
  NUMBER_OF_YEARS_TO_DISPLAY: 90,
  NUMBER_OF_WEEKS_PER_YEAR: 53,
  CANVAS_NEW_WIDTH: 370,
  CANVAS_NEW_HEIGHT: 500,
  CANVAS_LINE_WIDTH: 0.5
};