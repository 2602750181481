import React, { useCallback, useEffect, useState } from "react";
import { allAchievementsAtom } from "../atoms";
import { useRecoilState } from "recoil";
import { cloneAchievementMap, getAchievementFromAPI, updateAchievementDateToAPI } from "../helpers";
import debounce from "lodash/debounce";
import { makeStyles, TextField } from "@material-ui/core";

type IProps = {
  id: string,
}

const useStyle = makeStyles({
  wrapper: {
    width: "100%"
  }
});

const EditableAchievementDate = (props: IProps): React.ReactElement => {
  const classes = useStyle();

  const [textValue, setTextFieldValue] = useState<string>();
  const [allAchievements, setAllAchievements] = useRecoilState(allAchievementsAtom);
  const currentAchievement = allAchievements.get(props.id);

  useEffect(() => {
    if (!currentAchievement) return;

    setTextFieldValue(currentAchievement.date);
  }, []);

  const setDate = (date: string) => {
    if (!currentAchievement) return;

    setAllAchievements(cloneAchievementMap(allAchievements).set(props.id, {
      ...currentAchievement,
      date
    }));
  };

  const setDateToApi = (date: string) => {
    getAchievementFromAPI(props.id).then(value => {
      if (!value) return;

      updateAchievementDateToAPI(props.id, date);
    });
  };

  const debouncedSaveToAtom = useCallback(
    debounce((nextValue: string) => setDate(nextValue), 1000),
    []
  );

  const debouncedSaveToApi = useCallback(
    debounce((nextValue: string) => setDateToApi(nextValue), 1000),
    []
  );

  useEffect(() => {
    if (textValue === null || textValue === undefined) return;
    if (!currentAchievement) return;
    if (textValue === currentAchievement.date) return;

    debouncedSaveToAtom(textValue);
    debouncedSaveToApi(textValue);
  }, [textValue]);

  if (textValue === null || textValue === undefined) return <></>;

  return <TextField
    type={"date"}
    value={textValue}
    className={classes.wrapper}
    onChange={event => setTextFieldValue(event.target.value)}
  />;
};

export default EditableAchievementDate;
