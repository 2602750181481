import { API, graphqlOperation } from "aws-amplify";
import {
  createAchievement,
  createLife,
  deleteAchievement,
  deleteLife,
  updateAchievement,
  updateLife
} from "./graphql/mutations";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import {
  Achievement as AchievementFromAPI,
  CreateAchievementMutation,
  CreateLifeMutation,
  DeleteAchievementMutation,
  DeleteLifeMutation,
  GetAchievementQuery,
  GetLifeQuery,
  Life as LifeFromAPI,
  ListAchievementsQuery,
  ListLivesQuery
} from "./API";
import { Achievement, Life } from "./models";
import { AchievementMap, LifeMap } from "./atoms";
import { getAchievement, getLife, listAchievements, listLives } from "./graphql/queries";

export const createNewLife = async (): Promise<Life | null> => {
  const mutation = await (API.graphql(graphqlOperation(createLife, {
    input: {
      title: "Hello, World!",
      year: 2020
    }
  })) as Promise<GraphQLResult<CreateLifeMutation>>);
  const mutationData = mutation.data;
  if (!mutationData) return null;

  const lifeObjectReturned = mutationData.createLife;
  if (!lifeObjectReturned) return null;

  const title = lifeObjectReturned.title;
  if (!title) return null;

  const year = lifeObjectReturned.year;
  if (!year) return null;

  const id = lifeObjectReturned.id;
  return {
    id,
    title,
    year
  };
};

export const getAllLifes = async (): Promise<LifeMap | null> => {
  const graphqlResult = await (API.graphql(graphqlOperation(listLives)) as Promise<GraphQLResult<ListLivesQuery>>);
  const data = graphqlResult.data;
  if (!data) return null;

  const listedLifes = data.listLives;
  if (!listedLifes) return null;

  const items = listedLifes.items;
  if (!items) return null;

  return items.reduce((previousValue, currentValue) => {
    if (!currentValue) return previousValue;

    const title = currentValue.title;
    if (!title) return previousValue;

    const year = currentValue.year;
    if (!year) return previousValue;

    const deleted = currentValue._deleted;
    if (deleted) return previousValue;

    console.log(title, year, currentValue.id);
    return previousValue.set(currentValue.id, {
      id: currentValue.id,
      title,
      year
    });
  }, new Map() as LifeMap);
};

export const cloneLifeMap = (lifemap: LifeMap): LifeMap => Array
  .from(lifemap.values())
  .reduce((previousValue, currentValue) => {
    return previousValue.set(currentValue.id, currentValue);
  }, new Map<string, Life>());

export const cloneAchievementMap = (achievementMap: AchievementMap): AchievementMap => Array
  .from(achievementMap.values())
  .reduce((previousValue, currentValue) => {
    return previousValue.set(currentValue.id, currentValue);
  }, new Map<string, Achievement>());

export const updateTitleToAPI = async (id: string, title: string): Promise<void> => {
  await (API.graphql(graphqlOperation(updateLife, {
    input: {
      id,
      title
    }
  })));
};

export const updateYearToAPI = async (id: string, year: number): Promise<void> => {
  await (API.graphql(graphqlOperation(updateLife, {
    input: {
      id,
      year
    }
  })));
};

export const updateAchievementTitleToAPI = async (id: string, title: string): Promise<void> => {
  await (API.graphql(graphqlOperation(updateAchievement, {
    input: {
      id,
      title
    }
  })));
};

export const updateAchievementColorToAPI = async (id: string, color: string): Promise<void> => {
  await (API.graphql(graphqlOperation(updateAchievement, {
    input: {
      id,
      color
    }
  })));
};

export const updateAchievementDateToAPI = async (id: string, date: string): Promise<void> => {
  await (API.graphql(graphqlOperation(updateAchievement, {
    input: {
      id,
      date
    }
  })));
};

export const cloneAchievement = async (lifeID: string, title: string, color: string, date: string): Promise<Achievement | null> => {
  const mutation = await (API.graphql(graphqlOperation(createAchievement, {
    input: {
      title,
      color,
      date,
      lifeID
    }
  })) as Promise<GraphQLResult<CreateAchievementMutation>>);

  const mutationData = mutation.data;
  if (!mutationData) return null;

  const achievementCreated = mutationData.createAchievement;
  if (!achievementCreated) return null;

  return ({
    id: achievementCreated.id,
    title,
    date,
    color
  });

};

export const createNewAchievement = async (lifeID: string): Promise<Achievement | null> => {
  const mutation = await (API.graphql(graphqlOperation(createAchievement, {
    input: {
      title: "Something",
      color: "#371D1D",
      date: "2020-01-01",
      lifeID
    }
  })) as Promise<GraphQLResult<CreateAchievementMutation>>);

  const mutationData = mutation.data;
  if (!mutationData) return null;

  const achievementCreated = mutationData.createAchievement;
  if (!achievementCreated) return null;

  const title = achievementCreated.title;
  if (!title) return null;

  const color = achievementCreated.color;
  if (!color) return null;

  const date = achievementCreated.date;
  if (!date) return null;

  return ({
    id: achievementCreated.id,
    title,
    date,
    color
  });
};

export const getAllAchievements = async (lifeId: string): Promise<AchievementMap | null> => {
  const query = await (API.graphql(graphqlOperation(listAchievements, {
    filter: {
      lifeID: {
        eq: lifeId
      }
    }
  })) as Promise<GraphQLResult<ListAchievementsQuery>>);

  const queryData = query.data;
  if (!queryData) return null;

  const achievements = queryData.listAchievements;
  if (!achievements) return null;

  const items = achievements.items;
  if (!items) return null;

  return items.reduce((previousValue, currentValue) => {
    if (!currentValue) return previousValue;

    const title = currentValue.title;
    if (title === null || title === undefined) return previousValue;

    const color = currentValue.color;
    if (!color) return previousValue;

    const date = currentValue.date;
    if (!date) return previousValue;

    const deleted = currentValue._deleted;
    if (deleted) return previousValue;

    return previousValue.set(currentValue.id, {
      id: currentValue.id,
      title,
      color,
      date
    });
  }, new Map<string, Achievement>());
};

export const getLifeFromAPI = async (id: string): Promise<LifeFromAPI | null> => {
  const dataFromAPI = await (API.graphql(graphqlOperation(getLife, {
    id
  })) as Promise<GraphQLResult<GetLifeQuery>>);

  const data = dataFromAPI.data;
  if (!data) return null;

  const getLifeQuery = data.getLife;
  if (!getLifeQuery) return null;

  const title = getLifeQuery.title;
  if (!title) return null;

  const year = getLifeQuery.year;
  if (!year) return null;

  return getLifeQuery;
};

export const getAchievementFromAPI = async (id: string): Promise<AchievementFromAPI | null> => {
  const dataFromAPI = await (API.graphql(graphqlOperation(getAchievement, {
    id
  })) as Promise<GraphQLResult<GetAchievementQuery>>);

  const data = dataFromAPI.data;
  if (!data) return null;

  const getAchievementQuery = data.getAchievement;
  if (!getAchievementQuery) return null;

  const { title, color, date } = getAchievementQuery;
  if (title === null || title === undefined) return null;
  if (!color) return null;
  if (!date) return null;

  return getAchievementQuery;
};

export const deleteLifeFromAPI = async (id: string): Promise<void> => {
  await (API.graphql(graphqlOperation(deleteLife, {
    input: {
      id
    }
  })) as Promise<GraphQLResult<DeleteLifeMutation>>);
};

export const deleteAchievementFromAPI = async (id: string): Promise<void> => {
  await (API.graphql(graphqlOperation(deleteAchievement, {
    input: {
      id
    }
  })) as Promise<GraphQLResult<DeleteAchievementMutation>>);
};