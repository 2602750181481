import { CONSTANTS } from "./constants";

export const getCanvasContext = (
  canvasElement: HTMLCanvasElement
): CanvasRenderingContext2D | null => {
  const canvasContext = canvasElement.getContext("2d");
  if (!canvasContext) return null;

  canvasContext.lineWidth = CONSTANTS.CANVAS_LINE_WIDTH;
  return canvasContext;
};