import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import NewLifeButton from "../components/NewLifeButton";
import EditableLifeList from "../components/EditableLifeList";

const useStyle = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
});

const Home = (): React.ReactElement => {
  const classes = useStyle();

  return <Box className={classes.wrapper}>
    <NewLifeButton />
    <EditableLifeList />
  </Box>;
};

export default Home;
