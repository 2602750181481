import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useRecoilState } from "recoil";
import { allAchievementsAtom } from "../atoms";
import { cloneAchievementMap, deleteAchievementFromAPI, getAchievementFromAPI } from "../helpers";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyle = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  }
});

type IProps = {
  id: string,
}

const DeleteAchievementButton = (props: IProps): React.ReactElement => {
  const classes = useStyle();
  const [allAchievements, setAllAchievements] = useRecoilState(allAchievementsAtom);

  const onButtonClick = () => {
    getAchievementFromAPI(props.id).then(value => {
      if (!value) return;

      deleteAchievementFromAPI(props.id).then(() => {
        const newAchievements = cloneAchievementMap(allAchievements);
        newAchievements.delete(props.id);
        setAllAchievements(newAchievements);
      });
    });
  };

  return <Button onClick={onButtonClick} className={classes.wrapper}><DeleteIcon /></Button>;
};

export default DeleteAchievementButton;
