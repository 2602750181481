import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ROUTES } from "../Routes";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyle = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textDecoration: "none"
  }
});

type IProps = {
  id: string,
}

const EditLifeButton = (props: IProps): React.ReactElement => {
  const classes = useStyle();

  return <Link to={ROUTES.DETAILS(props.id)} className={classes.wrapper}>
    <Button><VisibilityIcon /></Button>
  </Link>;
};

export default EditLifeButton;
