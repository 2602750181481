import { atom } from "recoil";
import { Achievement, Life } from "./models";

export type LifeMap = Map<string, Life>;

export const allLifeAtom = atom<LifeMap>({
  key: "allLifes",
  default: new Map<string, Life>()
});

export type AchievementMap = Map<string, Achievement>;

export const allAchievementsAtom = atom<AchievementMap>({
  key: "allAchievements",
  default: new Map<string, Achievement>()
});