import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useRecoilState } from "recoil";
import { allAchievementsAtom } from "../atoms";
import { useParams } from "react-router-dom";
import { Params } from "../Routes";
import { getAllAchievements } from "../helpers";
import EditableAchievementLine from "./EditableAchievementLine";

const useStyle = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      alignItems: "stretch"
    }
  }
}));

const EditableAchievementList = (): React.ReactElement => {
    const classes = useStyle();
    const [allAchievements, setAllAchievements] = useRecoilState(allAchievementsAtom);
    const { lifeId } = useParams<Params>();

    useEffect(() => {
      if (!lifeId) return;

      getAllAchievements(lifeId).then(value => {
        if (!value) return;

        setAllAchievements(value);
      });
    }, []);

    return <Box className={classes.wrapper}>
      {Array.from(allAchievements.values())
        .sort((a, b) => {
          if (!a.date || !b.date) return -1;

          return (a.date < b.date ? -1 : 1);
        })
        .map(value => <EditableAchievementLine id={value.id} key={value.id} />)}
    </Box>;
  }
;

export default EditableAchievementList;
