import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import DeleteAchievementButton from "./DeleteAchievementButton";
import EditableAchievementTitle from "./EditableAchievementTitle";
import EditableAchievementColor from "./EditableAchievementColor";
import EditableAchievementDate from "./EditableAchievementDate";
import DuplicateAchievementButton from "./DuplicateAchievementButton";

const useStyle = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: "10px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  titleBox: {
    flexGrow: 1,
    paddingRight: "30px",
    paddingLeft: "10px"
  },
  dateBox: {
    maxWidth: "150px"
  },
  lineOne: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexGrow: 1
    }
  },
  lineTwo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  }
}));

type IProps = {
  id: string,
}

const EditableAchievementLine = (props: IProps): React.ReactElement => {
  const classes = useStyle();

  return <Box className={classes.wrapper}>
    <Box className={classes.lineOne}>
      <EditableAchievementColor id={props.id} />
      <Box className={classes.titleBox}>
        <EditableAchievementTitle id={props.id} />
      </Box>
    </Box>
    <Box className={classes.lineTwo}>
      <Box className={classes.dateBox}>
        <EditableAchievementDate id={props.id} />
      </Box>
      <DeleteAchievementButton id={props.id} />
      <DuplicateAchievementButton id={props.id} />
    </Box>
  </Box>;
};

export default EditableAchievementLine;
