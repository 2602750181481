import React from "react";
import { Fab, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Params } from "../Routes";
import { cloneAchievementMap, createNewAchievement } from "../helpers";
import { allAchievementsAtom } from "../atoms";
import { useRecoilState } from "recoil";
import AddIcon from "@material-ui/icons/Add";

const useStyle = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "fixed",
    bottom: "25px",
    [theme.breakpoints.up("md")]: {
      right: "25px"
    },
    [theme.breakpoints.down("md")]: {
      left: "25px"
    }
  }
}));

const NewAchievementButton = (): React.ReactElement => {
  const classes = useStyle();
  const { lifeId } = useParams<Params>();
  const [allAchievements, setAllAchievements] = useRecoilState(allAchievementsAtom);

  const onButtonClick = () => {
    if (!lifeId) return;

    createNewAchievement(lifeId).then(value => {
      if (!value) return;

      setAllAchievements(cloneAchievementMap(allAchievements).set(value.id, {
        id: value.id,
        title: value.title,
        color: value.color,
        date: value.date
      }));
    });
  };

  return <Fab onClick={onButtonClick} className={classes.wrapper}>
    <AddIcon />
  </Fab>;
};

export default NewAchievementButton;
