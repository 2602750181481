import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import DeleteLifeButton from "./DeleteLifeButton";
import EditableLifeTitle from "./EditableLifeTitle";
import EditLifeButton from "./EditLifeButton";
import EditableLifeYear from "./EditableLifeYear";

const useStyle = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  yearBox: {
    maxWidth: "3.5rem"
  },
  nameBox: {
    padding: "0px 10px"
  }
});

type IProps = {
  id: string,
}

const EditableLifeLine = (props: IProps): React.ReactElement => {
  const classes = useStyle();

  return <Box className={classes.wrapper}>
    <EditLifeButton id={props.id} />
    <Box className={classes.nameBox}>
      <EditableLifeTitle id={props.id} />
    </Box>
    <Box className={classes.yearBox}>
      <EditableLifeYear id={props.id} />
    </Box>
    <DeleteLifeButton id={props.id} />
  </Box>;
};

export default EditableLifeLine;
