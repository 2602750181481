/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://7hkse25yejhfbhuw7ghpomvmqa.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:06137196-bc17-4ac3-9c26-f4b9ef420e67",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_mUhdHl4L9",
    "aws_user_pools_web_client_id": "2bd30q11occfctloij09gm9qv3",
    "oauth": {
        "domain": "lifeissolongcb8dd384-cb8dd384-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://life.alex-adrien.com/,http://localhost:3000/",
        "redirectSignOut": "https://life.alex-adrien.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
