import React from "react";
import { AppBar, Box, makeStyles, Toolbar, Typography } from "@material-ui/core";

const useStyle = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  bar: {
    display: "flex",
    justifyContent: "center"
  }
});

const NavBar = (): React.ReactElement => {
  const classes = useStyle();

  return <Box className={classes.wrapper}>
    <AppBar position="static">
      <Toolbar className={classes.bar}>
        <Typography variant={"h6"}>Life is Long</Typography>
      </Toolbar>
    </AppBar>
  </Box>;
};

export default NavBar;
