/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLife = /* GraphQL */ `
  mutation CreateLife(
    $input: CreateLifeInput!
    $condition: ModelLifeConditionInput
  ) {
    createLife(input: $input, condition: $condition) {
      id
      title
      Achievements {
        nextToken
      }
      year
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateLife = /* GraphQL */ `
  mutation UpdateLife(
    $input: UpdateLifeInput!
    $condition: ModelLifeConditionInput
  ) {
    updateLife(input: $input, condition: $condition) {
      id
      title
      Achievements {
        nextToken
      }
      year
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteLife = /* GraphQL */ `
  mutation DeleteLife(
    $input: DeleteLifeInput!
    $condition: ModelLifeConditionInput
  ) {
    deleteLife(input: $input, condition: $condition) {
      id
      title
      Achievements {
        nextToken
      }
      year
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAchievement = /* GraphQL */ `
  mutation CreateAchievement(
    $input: CreateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    createAchievement(input: $input, condition: $condition) {
      id
      title
      color
      date
      lifeID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAchievement = /* GraphQL */ `
  mutation UpdateAchievement(
    $input: UpdateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    updateAchievement(input: $input, condition: $condition) {
      id
      title
      color
      date
      lifeID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAchievement = /* GraphQL */ `
  mutation DeleteAchievement(
    $input: DeleteAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    deleteAchievement(input: $input, condition: $condition) {
      id
      title
      color
      date
      lifeID
      createdAt
      updatedAt
      owner
    }
  }
`;
