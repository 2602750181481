import React, { useEffect, useState } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import signInInstructions from "./images/signInInstructions.png";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    backgroundImage: `url(${signInInstructions})`,
    backgroundPosition: "center",
    backgroundSize: "70%",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("sm")]: {
      backgroundSize: "20%"
    }
  }
}));

const AuthenticatedRoutes = (): React.ReactElement => {
  const classes = useStyles();
  const [isLoggedIn, setLoggedState] = useState<boolean>(false);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => setLoggedState(true))
      .catch(console.log);
  }, []);

  if (isLoggedIn) return <Routes />;

  return <Box className={classes.wrapper}>
    <Button onClick={() => Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google
    })}>Sign In</Button>
  </Box>;
};

export default AuthenticatedRoutes;
