import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import NavBar from "./components/NavBar";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import { configureAmplify } from "./AmplifyConfigure";

configureAmplify();

ReactDOM.render(
  <React.StrictMode>
    <NavBar />
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthenticatedRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
