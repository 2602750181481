import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { allAchievementsAtom } from "../atoms";
import { useRecoilState } from "recoil";
import { cloneAchievementMap, getAchievementFromAPI, updateAchievementTitleToAPI } from "../helpers";
import debounce from "lodash/debounce";

const useStyle = makeStyles({
  wrapper: {
    width: "100%"
  }
});

type IProps = {
  id: string,
}

const EditableAchievementTitle = (props: IProps): React.ReactElement => {
  const classes = useStyle();
  const [textValue, setTextFieldValue] = useState<string>();
  const [allAchievements, setAllAchievements] = useRecoilState(allAchievementsAtom);
  const currentAchievement = allAchievements.get(props.id);

  useEffect(() => {
    if (!currentAchievement) return;

    setTextFieldValue(currentAchievement.title);
  }, []);

  const setTitle = (title: string) => {
    if (!currentAchievement) return;

    setAllAchievements(cloneAchievementMap(allAchievements).set(props.id, {
      ...currentAchievement,
      title
    }));
  };

  const setTitleToApi = (title: string) => {
    getAchievementFromAPI(props.id).then(value => {
      if (!value) return;

      updateAchievementTitleToAPI(props.id, title);
    });
  };

  const debouncedSaveToAtom = useCallback(
    debounce((nextValue: string) => setTitle(nextValue), 1000),
    []
  );

  const debouncedSaveToApi = useCallback(
    debounce((nextValue: string) => setTitleToApi(nextValue), 1000),
    []
  );

  useEffect(() => {
    if (textValue === null) return;
    if (textValue === undefined) return;
    if (!currentAchievement) return;
    if (textValue === currentAchievement.title) return;

    debouncedSaveToAtom(textValue);
    debouncedSaveToApi(textValue);
  }, [textValue]);

  if (textValue === undefined || textValue === null) return <></>;

  return <TextField
    className={classes.wrapper}
    value={textValue}
    onChange={event => setTextFieldValue(event.target.value)}
  />;
};

export default EditableAchievementTitle;
