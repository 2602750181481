import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Detail from "./pages/Detail";

const Routes = (): React.ReactElement => {
  return <Switch>
    <Route exact path={ROUTES.HOME} component={Home} />
    <Route exact path={ROUTES.DETAILS(":lifeId")} component={Detail} />
  </Switch>;
};

export const ROUTES = {
  HOME: "/",
  DETAILS: (id: string): string => `/details/${id}`
};

export type Params = {
  lifeId?: string,
}

export default Routes;
