import React, { useCallback, useEffect, useState } from "react";
import { allAchievementsAtom } from "../atoms";
import { useRecoilState } from "recoil";
import { cloneAchievementMap, getAchievementFromAPI, updateAchievementColorToAPI } from "../helpers";
import debounce from "lodash/debounce";
import { ColorPicker } from "material-ui-color";

type IProps = {
  id: string,
}

const EditableAchievementColor = (props: IProps): React.ReactElement => {
  const [textValue, setTextFieldValue] = useState<string>();
  const [allAchievements, setAllAchievements] = useRecoilState(allAchievementsAtom);
  const currentAchievement = allAchievements.get(props.id);

  useEffect(() => {
    if (!currentAchievement) return;

    setTextFieldValue(currentAchievement.color);
  }, []);

  const setColor = (color: string) => {
    if (!currentAchievement) return;

    setAllAchievements(cloneAchievementMap(allAchievements).set(props.id, {
      ...currentAchievement,
      color
    }));
  };

  const setColorToApi = (color: string) => {
    getAchievementFromAPI(props.id).then(value => {
      if (!value) return;

      updateAchievementColorToAPI(props.id, color);
    });
  };

  const debouncedSaveToAtom = useCallback(
    debounce((nextValue: string) => setColor(nextValue), 1000),
    []
  );

  const debouncedSaveToApi = useCallback(
    debounce((nextValue: string) => setColorToApi(nextValue), 1000),
    []
  );

  useEffect(() => {
    if (!textValue) return;
    if (!currentAchievement) return;
    if (textValue === currentAchievement.color) return;

    debouncedSaveToAtom(textValue);
    debouncedSaveToApi(textValue);
  }, [textValue]);

  return <ColorPicker
    onChange={color => setTextFieldValue(`#${color.hex}`)}
    hideTextfield
    value={textValue}
  />;
};

export default EditableAchievementColor;
