import { CONSTANTS } from "./constants";
import { YearToDraw } from "./types";
import { AchievementMap } from "../atoms";
import moment from "moment";

export const computeWeekYearCircleXPosition = (
  week: number
): number => CONSTANTS.GRID_X_OFFSET + CONSTANTS.X_FACTOR * week;
export const computeWeekYearCircleYPosition = (
  year: number,
  startYear: number
): number => CONSTANTS.Y_OFFSET + CONSTANTS.Y_FACTOR * (year - startYear);
export const computeYearLabelXPosition = (): number => CONSTANTS.YEAR_LABEL_X_OFFSET;
export const computeYearLabelYPosition = (
  year: number,
  startYear: number
): number => CONSTANTS.Y_OFFSET + CONSTANTS.Y_FACTOR * (year - startYear);
export const computeYearsToDisplay = (startYear: number): Array<number> => Array(
  CONSTANTS.NUMBER_OF_YEARS_TO_DISPLAY
).fill(null)
  .map((
    value,
    index
  ) => startYear + index)
  .filter(value => value % 5 === 0);
export const computeYearsToDraw = (startYear: number, achievements: AchievementMap): Array<YearToDraw> => {
  const allMilestonesMap = Array
    .from(achievements.values())
    .reduce((
      previousValue,
      currentValue
    ) => {
      const currentAchievementDate = moment(currentValue.date, "YYYY-MM-DD");
      const color = currentValue.color;
      const isoWeekYear = currentAchievementDate.isoWeekYear();
      const isoWeek = currentAchievementDate.isoWeek();
      const yearConcerned = previousValue.get(isoWeekYear);
      if (!yearConcerned) return previousValue.set(isoWeekYear, new Map().set(isoWeek, color));

      return previousValue.set(isoWeekYear, yearConcerned.set(isoWeek, color || "#FFFFFF"));
    }, new Map<number, Map<number, string>>());

  return Array(
    CONSTANTS.NUMBER_OF_YEARS_TO_DISPLAY
  ).fill(null).map((value, index) => ({
    value: startYear + index,
    weeks: Array(CONSTANTS.NUMBER_OF_WEEKS_PER_YEAR)
      .fill(null)
      .map((value1, index1) => ({
        value: index1 + 1,
        color: allMilestonesMap.get(startYear + index)?.get(index1 + 1) || "#FFFFFF"
      }))
  }));
};