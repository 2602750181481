import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { cloneAchievement, cloneAchievementMap, getAchievementFromAPI } from "../helpers";
import { Params } from "../Routes";
import { useParams } from "react-router-dom";
import { allAchievementsAtom } from "../atoms";
import { useRecoilState } from "recoil";

const useStyle = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  }
});

type IProps = {
  id: string,
}

const DuplicateAchievementButton = (props: IProps): React.ReactElement => {
  const classes = useStyle();
  const { lifeId } = useParams<Params>();
  const [allAchievements, setAllAchievements] = useRecoilState(allAchievementsAtom);

  const onButtonClick = () => {
    getAchievementFromAPI(props.id).then(value => {
      if (!value) return;
      if (!value.id || !value.title || !value.color || !value.date) return;
      if (!lifeId) return;

      cloneAchievement(
        lifeId,
        value.title,
        value.color,
        value.date
      ).then(value1 => {
        if (!value1) return;

        setAllAchievements(cloneAchievementMap(allAchievements).set(value1.id, {
          id: value1.id,
          title: value1.title,
          color: value1.color,
          date: value1.date
        }));
      });
    });
  };

  return <Button onClick={onButtonClick} className={classes.wrapper}><FileCopyIcon /></Button>;
};

export default DuplicateAchievementButton;

