import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import NewAchievementButton from "../components/NewAchievementButton";
import EditableAchievementList from "../components/EditableAchievementList";
import WeeksGridWrapper from "../components/WeeksGridWrapper";

const useStyle = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  achievementList: {
    [theme.breakpoints.up("md")]: {
      order: 2,
      flexGrow: 1,
      paddingBottom: "50px",
      overflowY: "scroll",
      maxHeight: "calc(100vh - 150px)"
    }
  },
  grid: {
    [theme.breakpoints.up("md")]: {
      order: 1
    }
  }
}));

const Detail = (): React.ReactElement => {
  const classes = useStyle();

  return <Box className={classes.wrapper}>
    <NewAchievementButton />
    <Box className={classes.achievementList}>
      <EditableAchievementList />
    </Box>
    <Box className={classes.grid}>
      <WeeksGridWrapper />
    </Box>
  </Box>;
};

export default Detail;
