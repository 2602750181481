import {
  computeWeekYearCircleXPosition,
  computeWeekYearCircleYPosition,
  computeYearLabelXPosition,
  computeYearLabelYPosition,
  computeYearsToDisplay,
  computeYearsToDraw
} from "./compute";
import { CONSTANTS } from "./constants";
import { WeekToDraw, YearToDraw } from "./types";
import { AchievementMap } from "../atoms";

const drawYearLabel = (canvasContext: CanvasRenderingContext2D, startYear: number) => (
  year: number
) => {
  canvasContext.font = `18px Roboto`;
  canvasContext.fillStyle = "#000000";
  canvasContext.textBaseline = "middle";
  canvasContext.fillText(
    `${year}`,
    computeYearLabelXPosition(),
    computeYearLabelYPosition(year, startYear)
  );
};
export const drawYears = (canvasContext: CanvasRenderingContext2D, startYear: number): void => {
  const years = computeYearsToDisplay(startYear);
  years.forEach(drawYearLabel(canvasContext, startYear));
};
const drawWeek = (
  canvasContext: CanvasRenderingContext2D,
  year: number,
  startYear: number
) => (week: WeekToDraw) => {
  canvasContext.fillStyle = week.color;
  canvasContext.beginPath();
  canvasContext.arc(
    computeWeekYearCircleXPosition(week.value),
    computeWeekYearCircleYPosition(year, startYear),
    CONSTANTS.CIRCLE_RADIUS,
    0,
    Math.PI * 2,
    true
  );
  canvasContext.closePath();
  canvasContext.stroke();
  canvasContext.fill();
};
const drawYear = (canvasContext: CanvasRenderingContext2D, startYear: number) => (year: YearToDraw) => {
  year.weeks.forEach(drawWeek(canvasContext, year.value, startYear));
};
export const drawGrid = (canvasContext: CanvasRenderingContext2D, startYear: number, achievements: AchievementMap): void => {
  const years = computeYearsToDraw(startYear, achievements);
  years.forEach(drawYear(canvasContext, startYear));
};
export const cleanCanvas = (canvasContext: CanvasRenderingContext2D): void => {
  canvasContext.clearRect(
    0,
    0,
    CONSTANTS.CANVAS_INITIAL_WIDTH,
    CONSTANTS.CANVAS_INITIAL_HEIGHT
  );
};