import React, { RefCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { CONSTANTS } from "../canvas/constants";
import { resizeCanvas } from "../canvas/scaleUtils";
import { cleanCanvas, drawGrid, drawYears } from "../canvas/draw";
import { getCanvasContext } from "../canvas/tools";
import { AchievementMap } from "../atoms";

const useStyle = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  }
});

type IProps = {
  startYear: number,
  achievements: AchievementMap,
};

const WeeksGridTwo = (props: IProps): React.ReactElement => {
  const classes = useStyle();
  const [divWidth, setDivWidth] = useState<number>();
  const [divHeight, setDivHeight] = useState<number>();
  const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement>();

  useEffect(() => {
    if (!canvasRef) return;
    if (!divHeight) return;
    if (!divWidth) return;

    const canvasContext = getCanvasContext(canvasRef);
    if (!canvasContext) return;

    cleanCanvas(canvasContext);
    drawYears(canvasContext, props.startYear);
    drawGrid(canvasContext, props.startYear, props.achievements);
    resizeCanvas(canvasRef, divWidth, divHeight);
  }, [divWidth, divHeight, canvasRef, props.achievements]);

  const onCanvasLoad: RefCallback<HTMLCanvasElement> = (canvasElement) => {
    if (!canvasElement) return;

    setCanvasRef(canvasElement);
  };

  const onDivLoad: RefCallback<HTMLDivElement> = (divElement) => {
    if (!divElement) return;

    setDivWidth(divElement.offsetWidth);
    setDivHeight(divElement.offsetHeight);
  };

  return <div ref={onDivLoad} className={classes.wrapper}>
    <canvas
      ref={onCanvasLoad}
      width={CONSTANTS.CANVAS_INITIAL_WIDTH}
      height={CONSTANTS.CANVAS_INITIAL_HEIGHT} />
  </div>;
};

export default WeeksGridTwo;
